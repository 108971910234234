import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/Layout"
import Image from "../components/Image/Image"
import SEO from "../components/Seo/Seo"
import Footer from "../components/Footer/Footer"
import HeroHead from "../components/Header/HeroHead/HeroHead"
import HeroBody from "../components/Header/HeroBody/HeroBody"

const IndexPage = () => (
  <Layout header={false} footer={false}>
    <SEO title="Home" />
    <div>
    <section className="hero is-fullheight is-light">
      <HeroHead></HeroHead>
      <HeroBody></HeroBody>
      <Footer/>
    </section>
  </div>
  </Layout>
)

export default IndexPage

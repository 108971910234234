import React from "react"
import HouseParty from "../../HouseParty/HouseParty"
import Img from "gatsby-image"
import { useStaticQuery, Link } from "gatsby";
import "./HeroBody.scss";

const HeroBody = function() {
const data = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "homeperson.png" }) {
      childImageSharp {
        fixed(width: 270, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`);

return (
  <div className="hero-body p-b-30 ">
    <div className="container">
      <h1 className="title">
        <span className="is-size-2 has-text-centered is-block">
          Like a venue. But anywhere.
        </span>
      </h1>
      <h2 className="subtitle">
        <span className="has-text-centered is-block">
          We help ticket platforms host online events.
        </span>
      </h2>
      
      <div className="columns is-centered">
        <div className="column is-7">
          <div className="search-form ">
            <p className="has-text-centered is-vcentered">
              We're in a limited beta phase at the moment and we're looking for partners to launch with.
              <br/><br/>
              <Link className="button" to="/get-started/">
                Sign up and we'll be in touch
              </Link>
              <br/><br/>
            </p>
          </div>
        </div>
      </div>
      <div className="has-text-centered">
        <Img fixed={data.file.childImageSharp.fixed}  />
      </div>
    </div>
  </div>
);
}

export default HeroBody
